import './ServiceDesc.css'
import {  motion } from "framer-motion";
const ServiceDesc = ({image="../../img/school1.jpg", text="text", title="L’anglais pour les ingénieurs"}) => {
    const transition = {
        duration: 1,
        type: "spring",
        repeat: 1,
        repeatType: "reverse"     
    };
    return ( 
        <div className='ServiceDesc'>
            <div className='SD-Desc'>
                <span className='SD-title'>{title}</span>
                <br/>
                <span>
                    {text}

                </span>
                <br/>
                <a href="https://forms.gle/vy3mGestmrX2gVE87">
                    Let's start
                </a>
            </div>
            <div className='SD-right'>
              <motion.img 
              initial={{ x: "0%" }}
              animate={{ x: "100%" }}
              transition={transition}
              className='SD-image' src={image} alt="" />
            </div>
        </div>
     );
}
 
export default ServiceDesc;