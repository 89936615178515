import './OfferCard.css'
const OfferCard = ({image, text="Wifi"}) => {
    return (
    <div>
        <div className="OfferCard">
            <img className="FC-Offer" src={image} alt="something" />
            <div className="FC-text">
                {text}
            </div>
        </div>
    </div> 
    );
}
 
export default OfferCard;