import "./ServiceDescR.css";
import {  motion } from "framer-motion";
const ServiceDescR = ({image="../../img/school1.jpg", text="text", title="L’anglais pour les ingénieurs"} ) => {
    const transition = {
        duration: 1,
        type: "spring",
        repeat: 1,
        repeatType: "reverse"    
    };
    return ( 
        
        <div className='ServiceDescR'>
            <div className='SD-rightR'>    
                <motion.img 
                initial={{ x: "5%" }}
                animate={{ x: "-100%" }}
                transition={transition}
                className='SD-imageR' src={image} alt="" />
            </div>
            <div className='SD-DescR'>
            <span className='SD-title'> {title}</span>
                <br/>
                <span>
                    {text}

                </span>
                <br/>
                <a href="https://forms.gle/vy3mGestmrX2gVE87">
                    Let's start
                </a>
            </div>
                
        </div>
        
     );
}
 
export default ServiceDescR;