import ServiceDesc from "../serviceDesc/ServiceDesc";
import ServiceDescR from "../ServiceDescR/ServiceDescR";
import './ServicesLayer.css'
const ServicesLayer = () => {
    return ( 
    <div className="ServicesLayer">
        <div className="SLL-title" >
        An enjoyable<span className="SSLT-blue"> atmosphere of learning</span> empowered by creativity and enthusiasm.    
       
        </div>
        <div className="SL-desc">
        Delford offers a variety of English Language teaching specialty. Our main focus is on the international exams, special fields for professionals as well as general English.
        </div>
        <div>
            <ServiceDescR image={require('../../img/englishexam.jpg')} title="Exam preparation courses for proficiency tests" text="At Delford, we specialize in providing comprehensive international exam preparation courses for a variety of English language proficiency tests, including IELTS and other well-known exams. Our experienced instructors have a proven track record of success in preparing students for these exams, ensuring that you are fully prepared to excel on test day. Through our rigorous and personalized training programs, you will not only learn the content of the exam, but also the test-taking strategies and techniques that will give you a competitive edge. Our exam simulations are designed to replicate the conditions of the actual test, giving you the opportunity to practice and perfect your skills under real exam conditions."/>
            <ServiceDesc image={require('../../img/engg.jpg')} title ="English for Engineers" text="In our technical English training for engineers, we cover subjects  related to project writing, construction management and supervision, machinery, construction materials, foundations and structures, hydraulic works, and urban planning.At DELFORD INSTITUTE, our technical English methodology provides learners with the functional language they will need for actual practice of tasks in technical, industrial, and scientific sectors."/>
            <ServiceDescR image={require('../../img/travel.png')} title="English for traveling" text="English is the most influential language in the world. This course is designed to teach the different communication concepts necessary for traveling and dealing with various situations at the airport, hotel, or while shopping."/>
            <ServiceDesc image={require('../../img/medcin.png')} title="English for Medicine" text="Currently, the most prestigious medical journals are in English. This course emphasizes the individual needs of medical professionals and students and provides greater international mobility for attending conferences and symposiums."/>
            <ServiceDescR image={require('../../img/business.png')} title="English for Business and Corporate" text="One of the basic criteria for advancing in the business world is mastery of English, which includes developing communication skills for meetings, presentations, negotiations, and interviews, as well as business email writing, accounting, and marketing."/>
            
        </div>
    </div>
    );
}
 
export default ServicesLayer;