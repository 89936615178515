// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import './Comments.css'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import CommentsCard from "../CommentCard/CommentCard";



const Comments = () => {
    return ( 
        <div className="Comments">
            <div className="SLLL-title">
                What our <span className="SLT-blue">Students</span> say
            </div>
            
            <Swiper
                breakpoints={{
                    900: {
                    
                    slidesPerView: 3,
                    },
                    200: {
                    
                    slidesPerView: 1,
                    
                    },
                }}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={2}
                slidesPerView={3}
                navigation
                pagination={{ clickable: true }}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                >
                
                <SwiperSlide><CommentsCard image={require("../../img/ali.png")} name='Kratbi ali'  text ="I've had the chance to be trained at this institute for a while, Delford institute is a credible center for leaning English and enhancing professional linguistic capacities, it's among the rare centers with high methodological performances. At Delford, we can also get the chance to build a strong professional network, which is currently considered among the key factors of success, I highly recommande it specifically, for the raison of it's flexible management, by Mr Oussama"/></SwiperSlide>
                <SwiperSlide><CommentsCard image={require("../../img/sabrin.png")} name='sabrine saidi'  text ="An excellent training school so far, i'd truly recommend it to anyone who wishes to improve their communication skills in many foreign languages, their professionalism and customer service are unquestionable."/></SwiperSlide>
                <SwiperSlide><CommentsCard image={require("../../img/manel.png")} name='manel aitigrine'  text ="Très bonne école, personnel agréable et très professionnel, j'ai eu une super expérience je recommande vivement."/></SwiperSlide>
                <SwiperSlide><CommentsCard image={require("../../img/houda.png")} name='Houda Haddadou'  text ="Je mets 5 étoiles à l’école Delford car, l’accueil et la gentillesse du personnel est toujours au rendez-vous.
Merci à Mr Oussama de m’avoir aider à améliorer mon niveau d’anglais, et à m’exprimer correctement.
Si vous préparez votre IELTS vous êtes à la bonne porte."/></SwiperSlide>
                <SwiperSlide><CommentsCard image={require("../../img/hadil.png")} name='its._. hadiil'  text ='It was a great place to study at i learned a lot from all the teachers they are so friendly and made me enjoy studying i definitely recommend it.'/></SwiperSlide>
                <SwiperSlide><CommentsCard image={require("../../img/younes.png")} name='Mazouzi Younes'  text ='Mashallah, one of the most wholesome language school in Algiers. The English Program is very effective and they offer a multitude of other languages and courses to chose from. But what attracts me to most to it is the atmosphere at the school, everyone is so loving and understanding and you feel as if ur part of a family. I would 100% recommend to anyone looking for a school with a welcoming atmosphere and a good teaching method.'/></SwiperSlide>
                <SwiperSlide><CommentsCard image={require("../../img/juha.png")} name='Juhayemane Daouadji.C'  text ="I highly recommend this establishment. In effect I studied there for a while and learned so much, the teachers gave me exactly what I needed in terms of vocabulary, grammar and practice. Today I work and study in English and I find it easy after the great investment the school has put on me !!"/></SwiperSlide> 
                <SwiperSlide><CommentsCard image={require("../../img/walid.png")} name='WALYD BENMOUNA'  text ="i have been learning english at this school, my english was so bad at this time, but now after 2 years practicing english at delford institute. I reached B1-B2 level so I'm so satisfied for the result I reached, the atmosphere during the class, our teacher Oussama who tried to motivate us at every class. I highly recommend delford institute for everyone who wants to improve his english (speaking, writing and also listening)."/></SwiperSlide>
                <SwiperSlide><CommentsCard image={require("../../img/chafik.png")} name='chafik hamaidi'  text ="This place provides a comfortable environment for learning, they provide us with IELTS training. Don't hesitate to register and begin your journey."/></SwiperSlide>
                
            
            </Swiper>
            
        </div>
     );
}
 
export default Comments;