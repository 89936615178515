import './Offer.css'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import security from '../../img/security.png'
import parking from '../../img/parking.png'
import TR from '../../img/TR.png'
import CR from '../../img/CR.png'
import acs from '../../img/acs.png'
import wifi from '../../img/wifi.png'
import OfferCard from '../OfferCard/OfferCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Pagination, Scrollbar, A11y } from 'swiper';

function Offer() {
  return (
    <div className="Offer">
        <div className="SL-title">
            <span className="SLT-blue">What We Offer</span><br/>
            <p className='text_offer'>At our education center, we provide a variety of benefits and advantages for your successful education</p>
        </div>
        <Swiper className='W-Swiper_Offer'
                      breakpoints={{
                        900: {
                        
                        slidesPerView: 6,
                        },
                        500: {
                        
                        slidesPerView: 3,
                        },
                        200: {
                        
                        slidesPerView: 2,
                        },
                    }}
                    modules={[Pagination, Scrollbar, A11y]}
                    spaceBetween={2}
                    slidesPerView={6}
                    
                    pagination={{ clickable: true }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    >
                    <SwiperSlide><OfferCard image={security} text="Security Agent"></OfferCard></SwiperSlide>
                    <SwiperSlide><OfferCard image={parking} text="Parking space"></OfferCard></SwiperSlide>
                    <SwiperSlide><OfferCard image={TR} text="Means of transport"></OfferCard></SwiperSlide>
                    <SwiperSlide><OfferCard image={CR} text="Café Restaurant"></OfferCard></SwiperSlide>
                    <SwiperSlide><OfferCard image={acs} text="Accessible"></OfferCard></SwiperSlide>
                    <SwiperSlide><OfferCard image={wifi} text="Wifi"></OfferCard></SwiperSlide>
                    
                    
                    
        </Swiper>
    </div>
  )
}

export default Offer