import React from "react";
import { useState } from "react";
import "./Navbar.css";
import { Link } from "react-scroll";
import mainLogo from "../../img/logofin.png";
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="n-wrapper" id="Navbar">
      <div className="n-left">
        <img className="logo_n" src={mainLogo}  alt="delford"/>
        <div className="n-name">DELFORD</div>
      </div>
     
      <div className={`n-right  ${isOpen && "open"}`} >
        <div className="n-list">
          <ul style={{ listStyleType: "none" }}>
            <li>
              <Link activeClass="active" to="Navbar" spy={true} smooth={true}>
                Home
              </Link>
            </li>
            <li>
              <Link to="ServicesLayer" spy={true} smooth={true}>
                Serivces
              </Link>
            </li>
            <li>
              <Link to="Aboutus" spy={true} smooth={true}>
                AboutUs
              </Link>
            </li>
            <li>
              <Link to="footer" spy={true} smooth={true}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <Link  to="Contact" spy={true} smooth={true}>
           <button className="button n-button">   Contact Us   </button>
        </Link>
      </div>
      <div
        className={`nav-toggle ${isOpen && "open"}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="bar"></div>
      </div>
    </div>
  );
};

export default Navbar;