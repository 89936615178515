import "./fonts/Manrope-Regular.ttf"
import "./fonts/Manrope-Bold.ttf"
import "./fonts/Manrope-ExtraBold.ttf"
import "./fonts/Manrope-ExtraLight.ttf"
import "./fonts/Manrope-Light.ttf"
import "./fonts/Manrope-Medium.ttf"
import "./fonts/Manrope-SemiBold.ttf"

import Navbar from "./Components/Navbar/Navbar";
import './App.css'
import Intro from "./Components/Intro/Intro"
import Work from "./Components/work/Work"
import Footer from "./Components/Footer/Footer"
import AboutUs from "./Components/Aboutus/AboutUs"

import ServicesLayer from "./Components/servicesLayer/ServicesLayer"
import Comments from "./Components/Comments/Comments"
import Offer from "./Components/Offer/Offer"
import Contact from "./Components/contactus/Contact"
function App() {
  return (
    <div className="App">
      <Navbar></Navbar>
      <Intro/>
      <AboutUs/>
      <ServicesLayer/>
      <Work/>
      <Comments/>
      <Offer/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
