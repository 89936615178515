import './Intro.css'
import {BsFacebook, BsInstagram} from 'react-icons/bs'
import Vector1 from "../../img/Vector1.png";
import Vector2 from "../../img/Vector2.png";
import girl from "../../img/introimg.png";
import image4 from "../../img/delf1.jpeg";
import image5 from "../../img/delf2.jpeg";
import image6 from "../../img/delf3.jpeg";
import image7 from "../../img/delf7.jpeg";
import image8 from "../../img/delf8mask.png";
import image9 from "../../img/delf9.jpeg";
import image10 from "../../img/delf10.jpeg";
import image11 from "../../img/delf11.jpeg";
import thumbup from "../../img/thumbup.png";
import crown from "../../img/crown.png";
import FloatingDiv from '../floatingDiv/FloatingDiv';
import {  motion } from "framer-motion";
const Intro = () => {

    
    
    const transition = {
        duration: 2,
        type: "spring",
        repeat: 2,
        repeatType: "reverse"
           
    };
    return ( 
    <div className="intro">
        <div className="i-left">
            <div className="i-name">
                <span>&quot;There are no foreign lands. It is the traveler only who is foreign.&quot;</span><span>Robert Louis Stevenson</span>
                <span>
                Join our diverse community at Delford and Make a jump forward…</span>
            </div>
            <div className='i-btnicon'>
                <a href='https://forms.gle/vy3mGestmrX2gVE87'>
                    <button className="button i-button">
                        Register now!
                    </button>
                </a>
                <div className="i-icons">
                    <a href="https://www.facebook.com/delfordinstitute1/">
                    <BsFacebook size={30} color='#14448C' />
                    </a>
                    <a href="https://www.instagram.com/delford.institute/">
                    <BsInstagram size={30} color='#14448C' />
                    </a>
                    <a className='i-Test' href="https://quizizz.com/embed/quiz/662eb704fe326c605e58372d">
                    <p> Discover Your English Level Here</p>

                    </a>
                    
                    
                </div>
            </div>
            
        </div>
        <div className="i-right">
            <img src={Vector2} alt="" />
            <img src={Vector1} alt="" />
            
            <img src={image8} alt="" />
            <motion.img 
                initial={{ left: "-24" }}
                animate={{ left: "-36%" }}
                transition={transition}
                src="" alt=""/>
            <motion.div
                animate={{ left: "60%" }}
                initial={{top: '-4%', left:'50%'}}
                transition={transition}
                >
                <FloatingDiv className='i-floating' image={crown} txt1='Unlock your potential.' txt2=''></FloatingDiv>
            </motion.div>
            <motion.div 
                animate={{ left: "30%" }}
                initial={{top: '60%', left:'20%'}}
                transition={transition}
                >
                <FloatingDiv className='i-floating' image={thumbup} txt1='Discover a new world.' txt2=''></FloatingDiv>
            </motion.div>


            {/*only bluuure effect here*/ }

            <div className='blur' style={{background:"rgb(238 210 255)"}}></div>
            <div className='blur' style={{
                background: 'C1F5FF',
                top: '17rem',
                width: '21rem',
                height: '11rem',
                left: '-9rem'
            }}></div>



        </div>
    </div>
    );
}
 
export default Intro;