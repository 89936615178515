import './Work.css'
import {  motion } from "framer-motion";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import france from "../../img/france.png"
import engl from "../../img/eng.png"
import espagnol from "../../img/espagnol.png"
import italy from "../../img/italy.png"
import germany from "../../img/germany.png"
import FlagCards from '../flagCards/FlagCards';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

const Work = () => {
    const transition = {
        duration: 2,
        type: "spring",
        repeat: Infinity,
        repeatType: "reverse"
           
    };
    return ( 
        <div className="works">
            <div className="awesome">
                <span>Learn With us diffrent</span>
                <span>Languages</span>
                <span>
                Unlock your linguistic potential with our diverse array of language courses at Delford, offering a world of opportunities.
                <br/>
                    
                    
                </span>

                
                <Swiper className='W-Swiper'
                    breakpoints={{
                        900: {
                        
                        slidesPerView: 4,
                        },
                        200: {
                        
                        slidesPerView: 3,
                        },
                    }}
                    modules={[Pagination, Scrollbar, A11y]}
                    spaceBetween={2}
                    slidesPerView={4}
                    
                    pagination={{ clickable: true }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    >
                    <SwiperSlide><FlagCards image={france} text= "French"></FlagCards></SwiperSlide>
                    <SwiperSlide><FlagCards image={engl} text="English"></FlagCards></SwiperSlide>
                    <SwiperSlide><FlagCards image={espagnol} text="Spanish"></FlagCards></SwiperSlide>
                    <SwiperSlide><FlagCards image={germany} text="German"></FlagCards></SwiperSlide>
                    
                    
                    
                    
                </Swiper>

                    
              
                
                <a href="https://forms.gle/vy3mGestmrX2gVE87"><button className="button s-button"> Register</button></a>
                <div className="blur s-blur1" style={{background: "#ABF1FF94"}}></div>
            </div>
            <div className='w-right'>
                <motion.div
                    initial={{ rotate: 45 }}
                    animate={{ rotate: 0 }}
                    viewport={{ margin: "-40px" }}
                    transition={transition}
                    className="w-mainCircle"
                >
                    <div className='w-secCircle'>
                        <img src={espagnol} alt=''/>

                    </div>
                    <div className='w-secCircle'>
                        <img src={italy} alt=''/>

                    </div>
                    <div className='w-secCircle'>
                        <img src={germany} alt=''/>

                    </div>
                    <div className='w-secCircle'>
                        <img src={france} alt=''/>

                    </div>
                    <div className='w-secCircle'>
                        <img src={engl} alt=''/>

                    </div>
                </motion.div>
                 {/* background Circles */}
                <div className="w-backCircle blueCircle"></div>
                <div className="w-backCircle yellowCircle"></div>      
            </div>
        </div>
     );
}
 
export default Work;