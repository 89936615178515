import './FlagCards.css'
const FlagCards = ({image, text="french"}) => {
    return (
    <div>
        <div className="FlagCards">
            <img className="FC-flag" src={image} alt="something" />
            <div className="FC-text">
                {text}
            </div>
        </div>
    </div> 
    );
}
 
export default FlagCards;