import './Contact.css'

const Contact = () => {
    const linkform="https://getform.io/f/8e4cca7d-ddbf-4f34-98a9-9fae786e2c50";
    return ( 
        <div className='Contact'>
            <form method='POST' action={linkform} className='C-Form'>
                <p>Contact us</p>
                <p>Submit the form below and send us a message</p>
                <input name='name' type="text" placeholder='Name' className='Con-Name' />
                <input name='email' type="email" placeholder='Email' className='Con-Email' />
                <textarea name="message" cols="30" rows="10" placeholder='Message ...' className='Con-Message'></textarea>
                <button className='button Con-Btn'> Send the message</button>
            </form>
        </div>
     );
}
 
export default Contact;