import "./CommentCard.css";
// Import Swiper styles
import 'swiper/css';
const CommentCard = ({image, name='LaZa', text}) => {
    
    return ( 
        <div className="container">
          <div class="avatar">
            <img src={image} height="100" width="100" />
          </div>
          <div className="content">
            <span className="nameCard">{name}</span>
            <p className="comment">{text}</p>
          </div>
        </div>
     );
}
 
export default CommentCard;