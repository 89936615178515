import React from 'react'
import "./Footer.css";
import facebook from '../../img/fb.png';
import instagram from '../../img/insta.png';
import { Link } from "react-scroll";
function Footer() {
  return (
    <footer class="footer">

    <div class="footer-left">
        <h3>DEL<span>FORD</span></h3>

        <p class="footer-links">
            <ul style={{ listStyleType: "none" }}>
                <li>
                <Link activeClass="active" to="Navbar" spy={true} smooth={true}>
                    Home
                </Link>
                </li>
                <li>
                <Link to="ServicesLayer" spy={true} smooth={true}>
                    Serivces
                </Link>
                </li>
                <li>
                <Link to="Aboutus" spy={true} smooth={true}>
                    AboutUs
                </Link>
                </li>
                <li>
                <Link to="footer" spy={true} smooth={true}>
                    Contact
                </Link>
                </li>
            </ul>
        </p>

        <p class="footer-company-name">Copyright © 2023/24 <strong>Developped by GaraGenius for devepment</strong><br/> All rights reserved</p>
    </div>

    <div class="footer-center">
        <div>
            <i class="localisation"></i>
            <p><span>Algérie</span>
            Dély Ibrahim RTT 13 (la cité), Alger. <br/><br/> Résidence Badin n°24,
Boumerdes.</p>
        </div>

        <div>
            <i class="phone"></i>
            <p>☎️ 07 70 20 70 13 / 05 58 56 55 03</p>
        </div>
        <div>
            <i class="mail"></i>
            <p><a >Delford.uk@gmail.com</a></p>
        </div>
        <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12785.980822243697!2d2.9808616!3d36.758686!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128fb127b07c41e1%3A0x9fd8ff91aaaad69d!2sDelford%20institute!5e0!3m2!1sfr!2sdz!4v1677550765678!5m2!1sfr!2sdz"></iframe>
        </div>
    </div>
    <div class="footer-right">
        <p class="footer-company-about">
            <span>About US</span>
            <strong>Delford Institute :</strong> 
            At Delford Institute, we offer a wide range of courses, including language learning and professional training, taught by highly experienced instructors who can cater to your individual needs. Our courses are varied, ranging from conversation classes to exam preparation courses. By joining our community of language learners and skills seekers, you'll be taking the first step towards mastering your desired language and enhancing your skillset. Come and be a part of our welcoming community and unlock your full potential today.
        </p>
        <div class="footer-icons">
            <a href="https://www.facebook.com/delfordinstitute1/">
                <img src={facebook}/> 
                Delford Institute</a>
            <a href="https://www.instagram.com/delford.institute/">
                <img src={instagram}/>
                delford.institute</a>
            
        </div>
    </div>
</footer>
  )
}

export default Footer