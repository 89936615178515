import './AboutUs.css'
import image1 from "../../img/school1.jpg";
import image2 from "../../img/aboutus1.png";
import image3 from "../../img/englishexam.jpg";
import image4 from "../../img/delf1.jpeg";
import image5 from "../../img/delf2.jpeg";
import image6 from "../../img/delf3.jpeg";
import image7 from "../../img/delf7.jpeg";
import image8 from "../../img/delf8.jpeg";
import image9 from "../../img/delf9.jpeg";
import image10 from "../../img/delf10.jpeg";
import image11 from "../../img/delf11.jpeg";
import { useState } from 'react';

import _ from 'lodash';
const AboutUs = () => {
    const [images, setImages] = useState([image5, image7, image4]);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         const newImages = _.shuffle(images);
    //         setImages(newImages);
    //     }, 3000);

    //     console.log(images)

    //     console.log("psssssssssssssss")
    //     return () => clearInterval(interval)
    // }, [])

    const setUpImage = (image) => (e) => {
        const newArray= images.filter(el=> el!=image);
        setImages([...newArray, image]);
        // Array.from(document.getElementsByClassName('A-image')).forEach(e => e.style.zIndex = -1);
        // e.target.style.zIndex = 50
    }

    return (

        <div className="Aboutus">
            <div className="left">
                <span className='A-Blue A-title'>About </span><span className='A-Yellow A-title'>US</span>
                <br></br>
                <div className='A-text'>
                    <span className='Bold-Name'>Delford</span>, Institute is a language center and an international exams institute, we are specialized in the general English language, the preparation of international exams as well English for specific purposes which is destined to companies and professionals.
                    Our training will contribute to increasing the level of qualifications and secure the professional career path of
of the learners. Our lesson plans are adapted to your needs, whether you are a beginner or a more or more advanced, you will learn at your own pace.

                </div>
            </div>
            <div className="right">
                {/* <img src={image3} alt=""/>
                <img src={image2} alt=""/>
                <img src={image1} alt=""/> */}

                {images.map((image, i) => <img className="A-image" key={i} src={image} alt="" onMouseOut={setUpImage(image)} />)}
            </div>
        </div>
    );
}

export default AboutUs;